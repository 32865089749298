.header-general {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 2.75rem;

  &__add {
    position: absolute;
    right: 6.25rem;
    bottom: 0;
    transform: translateY(50%);
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    background-color: var(--red-100) !important;
    color: white !important;
    font-size: 1.75rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;
    cursor: pointer;
    text-decoration: none;
  }

  &__title {
    position: relative;
    color: white;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
    padding: 0 1.25rem 0 0.875rem;

    &::after {
      content: '';
      position: absolute;
      height: 2.25rem;
      width: 0.0625rem;
      background-color: white;
      top: calc(-1.125rem / 2);
      right: 0;
    }
  }

  &__bee {
    color: var(--wizbii-bee);
    font-size: 1rem;
  }

  &__search {
    color: white;
    display: inline-flex;
    align-items: center;
  }

  &__select {
    width: 15rem;
    color: white;
    position: relative;
    margin-right: 1.5rem;

    &::before {
      content: '';
      position: absolute;
      height: 2.25rem;
      width: 0.0625rem;
      background-color: white;
      top: calc(1.5rem / 2);
      left: 0;
    }
  }
}
