@use 'variables/size';
@use 'variables/colors';

.badge {
  display: inline-block;
  border-radius: 6px;
  font-size: size.$xs;
  font-weight: 500;
}

@each $name, $color in colors.$colors-list {
  .badge--#{$name} {
    padding: 0.125rem 0.375rem;
    border-radius: 3px;
    background-color: $color;
    box-shadow: 0 0.125rem 0.3125rem 0 var(--box-shadow-10);
    font-size: 0.625rem;
    color: white;
    font-weight: 500;
  }

  .badge--white-#{$name} {
    padding: 0.125rem 0.375rem;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0.125rem 0.3125rem 0 var(--box-shadow-10);
    font-size: 0.625rem;
    color: $color;
    font-weight: 500;
  }

  .badge--bordered-#{$name} {
    border: 1px solid $color;
    color: $color;
    padding: 0.9375rem 1.25rem;
  }
}
