.header-detail {
  display: flex;
  justify-content: space-between;

  &__content {
    display: flex;
  }
  &__title {
    font-weight: 500;
    font-size: 1.75rem;
    margin: 0;
  }

  &__back-arrow {
    margin: 1rem 0.75rem 0 0;

    &__picto {
      color: white;
    }
  }

  &__left {
    &__tag {
      display: inline-block;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      background-color: var(--orange-100);
      color: white;
      border-radius: 6px;
      font-weight: 500;
      margin-right: 0.625rem;

      &--job_dating {
        background-color: var(--orange-100);
      }

      &--cocktail_recrutement {
        background-color: var(--green-100);
      }

      &--cocktail-entrepreneurial {
        background-color: var(--red-100);
      }

      &--evenement-prive {
        background-color: var(--purple-100);
      }
    }

    &__name {
      display: flex;
      align-items: center;
      color: white;
      font-weight: 500;
      font-size: 1.75rem;
      margin: 0.375rem 0;

      &__bee {
        display: inline-block;
        margin-right: 0.875rem;
        color: var(--wizbii-bee);
        font-size: 1rem;
      }
    }

    &__city {
      color: white;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  &__right {
    display: flex;
    align-items: center;

    &__goal {
      display: flex;
      align-items: center;
      color: white;
      margin: 0 1.875rem;
      font-size: 1.125rem;

      &--picto {
        margin-right: 0.5rem;
      }

      &--big {
        font-size: 2.25rem;
      }
    }
    &__date {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      border-radius: 6px;
      overflow: hidden;

      &__month {
        background-color: var(--sky-blue-15);
        color: var(--sky-blue-100);
        text-align: center;
        font-size: 0.6875rem;
        padding: 0.125rem 0.5rem;
      }

      &__number {
        color: var(--purple-100);
        background-color: white;
        font-size: 1.125rem;
        text-align: center;
        padding: 0.3125rem 0;
        font-weight: 500;
      }
    }
  }
}
