@use 'sass:color' as color;

%brand-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 8.5rem;
  padding: 0.875rem 1.25rem;
  background-color: var(--purple-100);
  box-shadow: 0 0.625rem 0.9375rem 0 var(--box-shadow-brand-btn-small);
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline-offset: 1px;
  outline-color: var(--purple-100);
  transition:
    color 0.15s ease-out,
    background-color 0.15s ease-out,
    opacity 0.15s ease-out,
    box-shadow 150ms ease-out,
    transform 150ms ease-out,
    color 150ms ease-out;
  cursor: pointer;
  position: relative;
  opacity: 1;
  text-decoration: none;

  &__icon {
    margin-left: 0.625rem;
    transition: transform 0.15s ease-out;
  }

  &__icon--left {
    margin-right: 0.625rem;
    transition: transform 0.15s ease-out;
  }

  &--loading:disabled {
    opacity: 1;
    color: white;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--green-100);
    transform: scaleX(0.8);
    transform-origin: 0;
    animation: loade-anim 1s ease-out;
    z-index: 10;
  }

  @keyframes loade-anim {
    0% {
      transform: scaleX(0.1);
    }

    30% {
      transform: scaleX(0.3);
    }

    80% {
      transform: scaleX(0.8);
    }
  }

  &:hover {
    color: white;
    text-decoration: none;
    transform: scale(1.02);

    & > .brand-btn__icon {
      transform: scale(1.1) translateX(0.125rem);
    }
  }

  &:active {
    transform: scale(0.97);
  }

  &:disabled, // use attribute `disabled` for buttons
  &--disabled {
    // use class `btn--disabled` with `tabindex="-1"` for links
    opacity: 0.3;
    pointer-events: none;
  }

  &--link.brand-btn {
    // increased specificity required due to theming
    min-width: 0;
    background-color: transparent;
    color: inherit;
    outline-color: currentcolor;
    font-weight: 500;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  &--ghost.brand-btn {
    @extend %brand-btn--ghost;
  }

  &--full.brand-btn {
    @extend %brand-btn--full;
  }

  &--shadow {
    box-shadow: 0 0.125rem 0.25rem 0 var(--box-shadow-brand-btn);
    transition:
      color 0.15s ease-out,
      background-color 0.15s ease-out,
      opacity 0.15s ease-out,
      box-shadow 150ms ease-out,
      transform 150ms ease-out,
      color 150ms ease-out;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 0.1275rem 0.255rem 0 var(--box-shadow-brand-btn);
    }

    &:active {
      transform: scale(0.97);
      box-shadow: 0 0.1213rem 0.2425rem 0 var(--box-shadow-brand-btn);
    }
  }

  &--small {
    font-size: 0.75rem;
    padding: 0.625rem;
    box-shadow: 0 0.0625rem 0.9375rem 0 var(--box-shadow-brand-btn-small);
  }
}

%brand-btn--ghost {
  // increased specificity required due to theming
  color: inherit;
  background-color: transparent;
  border: 1px solid var(--purple-100);
  transition:
    background-color 150ms ease-out,
    color 150ms ease-out;
  box-shadow: none;

  &:hover {
    color: white;
    background-color: var(--purple-100);
  }
}

%brand-btn--full {
  width: 100%;
}

.brand-btn {
  @extend %brand-btn;
}
