@use 'sass:math' as math;
@use 'sass:map' as map;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
/* stylelint-disable unit-disallowed-list */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@function px-to-em($val) {
  @return math.div($val, 16px) * 1em;
}

/**
 * BREAKPOINTS
 * Bootstrap's breakpoints in `em` - https://github.com/twbs/bootstrap/blob/v4.1.1/scss/_variables.scss#L168
 * Use these variables to style the main three layouts (mobile/tablet/desktop).
 * Use your own values for more precise control (multiples of 5 or 10 are usually fine).
 */
$bp-sm: px-to-em(map.get($grid-breakpoints, sm));
$bp-sm-max: px-to-em((map.get($grid-breakpoints, sm) - 1));
$bp-md: px-to-em(map.get($grid-breakpoints, md));
$bp-md-max: px-to-em((map.get($grid-breakpoints, md) - 1));
$bp-lg: px-to-em(map.get($grid-breakpoints, lg));
$bp-lg-max: px-to-em((map.get($grid-breakpoints, lg) - 1));
$bp-xl: px-to-em(map.get($grid-breakpoints, xl));
$bp-xl-max: px-to-em((map.get($grid-breakpoints, xl) - 1));
$bp-xxl: 79.75em;
/* stylelint-enable unit-disallowed-list */
