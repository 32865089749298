@use 'sass:color' as color;

.ngx-slider {
  .ngx-slider-bar {
    background-color: var(--purple-15) !important;
    height: 0.375rem !important;

    &--sky-blue {
      background-color: var(--sky-blue-15) !important;
    }

    &--navy-blue {
      background-color: var(--navy-blue-15) !important;
    }
  }

  .ngx-slider-selection {
    background-color: var(--purple-100) !important;
  }

  .ngx-slider-pointer {
    width: 1.625rem !important;
    height: 1.625rem !important;
    top: -0.6rem !important;
    transform: translateX(-0.437rem) !important;
    background-color: var(--purple-100) !important;
    border: 0.25rem solid white !important;
    outline: none;

    &::after {
      display: none !important;
    }
  }

  .ngx-slider-bubble {
    bottom: 1.375rem !important;
    transform: translateX(-0.5rem) !important;
    font-weight: bold !important;
    padding: 0 0.5rem !important;
    border-radius: 16px !important;
    font-size: 0.75rem !important;
    transition: left 0.4s ease-out;
    color: var(--midnight-blue-100) !important;
  }

  .ngx-slider-limit {
    font-weight: 500 !important;
    color: var(--slider-limit);
  }

  .ngx-slider-tick {
    width: 0.15rem !important;
    height: 0.625rem !important;
    margin-left: 0.25rem !important;
    border-radius: 0 !important;
    background-color: var(--purple-100) !important;
    top: 0.065rem !important;

    &.ngx-slider-selected {
      background-color: var(--purple-100) !important;
    }
  }

  .ngx-slider-tick-legend {
    white-space: nowrap !important;
    font-size: 0.75rem !important;
  }

  .ngx-slider-inner-tooltip {
    opacity: 0.4;
    color: var(--midnight-blue-100);
    font-size: 0.75rem;
    font-weight: 500;
  }

  &--sky-blue {
    .ngx-slider-selection,
    .ngx-slider-pointer,
    .ngx-slider-tick,
    .ngx-slider-tick.ngx-slider-selected {
      background-color: var(--sky-blue-100) !important;
    }
  }

  &--navy-blue {
    .ngx-slider-selection,
    .ngx-slider-pointer,
    .ngx-slider-tick,
    .ngx-slider-tick.ngx-slider-selected {
      background-color: var(--navy-blue-100) !important;
    }
  }
}
