.mat-icon {
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  line-height: 0;
  height: 1.5rem;
  width: 1.5rem;
  fill: transparent;
  stroke: transparent;
  font-size: 1rem;
}
