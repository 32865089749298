@mixin wzbCkeditor($primary) {
  // stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type
  .wzb-ckeditor {
    .ck-editor__editable_inline {
      box-sizing: border-box !important;
      min-height: 18.75rem !important;
      border-radius: 6px !important;
      border: solid 0.0625rem var(--midnight-blue-10) !important;
      padding: 1rem 2rem !important;
      margin-top: 0.5rem !important;
      max-width: calc(100vw - 2.5rem) !important;
      background-color: white;
    }

    .ck-focused {
      border: 1px solid $primary !important;
      box-shadow: none !important;
      outline-offset: 0.3125rem !important;
    }

    .ck-toolbar {
      border-radius: 20px !important;
      border: solid 0.0625rem var(--midnight-blue-10) !important;
      padding: 0 2rem !important;
      max-width: 99% !important;
      background: white !important;
    }

    .ck-toolbar_vertical {
      border-radius: 0 !important;
      padding: 0 !important;
    }

    .ck-list-styles-dropdown > .ck-dropdown__panel > .ck-toolbar {
      border-radius: 0 !important;
      padding: 0 !important;
    }

    .ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    &--error {
      .ck-editor__editable_inline {
        border: 1px solid var(--red-100) !important;
      }
    }
  }
}
