@use 'sass:color' as color;

:root {
  // purple
  --purple-100: #8683ff;
  --purple-15: #edecff;
  --purple-5: #f9f9ff;

  // midnight blue
  --midnight-blue-100: #000028;
  --midnight-blue-10: #e6e6ea;
  --midnight-blue-5: #f2f2f4;

  // navy blue
  --navy-blue-100: #054189;
  --navy-blue-40: #9bb3d0;
  --navy-blue-15: #dae2ed;
  --navy-blue-5: #f0f4f8;

  // sky blue
  --sky-blue-100: #2199cc;
  --sky-blue-15: #def0f7;
  --sky-blue-5: #f4fafc;

  // red
  --red-100: #df4545;
  --red-15: #fae3e3;
  --red-5: #fdf6f6;

  // green
  --green-100: #11b684;
  --green-15: #dbf4ed;
  --green-5: #f3fbf9;

  // orange
  --orange-100: #fa9831; // rgb(250, 152, 49)
  --orange-15: #fef0e0; // rgb(254, 240, 224)
  --orange-5: #fffaf5; // rgb(255, 250, 245)

  // yellow
  --yellow-100: #eec031;
  --yellow-15: #fcf6e0;
  --yellow-5: #fefcf5;

  // pink
  --pink-100: #dc90e9;
  --pink-15: #faeefc;
  --pink-5: #fdf9fe;

  // brand
  --wizbii-bee: #3df9c6;

  // OLD COLORS

  --grey-dark: #5f7484;
  --status-event-draft: var(--grey-dark);
  --box-shadow-4: rgb(0 0 0 / 4%);
  --box-shadow-5: rgb(0 0 0 / 5%);
  --box-shadow-6: rgb(0 0 0 / 5%);
  --box-shadow-8: rgb(0 0 0 / 8%);
  --box-shadow-10: rgb(0 0 0 / 10%);
  --box-shadow-12: rgb(0 0 0 / 12%);
  --box-shadow-14: rgb(0 0 0 / 14%);
  --box-shadow-15: rgb(0 0 0 / 15%);
  --box-shadow-20: rgb(0 0 0 / 20%);
  --box-shadow-40: rgb(0 0 0 / 40%);
  --default-box-shadow: rgb(115 107 200 / 20%);
  --box-shadow-brand-btn: rgb(134 130 255 / 23%);
  --box-shadow-brand-btn-small: rgb(134 131 255 / 20%);
  --box-shadow-btn-candidate: rgb(99 93 191 / 30%);
  --box-shadow-card: rgb(227 227 227 / 50%);
  --box-shadow-white: rgb(255 255 255 / 70%);
  --box-shadow-grey-blue: rgb(116 126 163 / 45%);
  --modal-backdrop: #22223ab3; // TODO
  --purple-light-alpha: #8782ff1a; // TODO
  --slider-limit: #55637d;
}

$colors-list: (
  'blue': #399af2,
  'pink': #f64a91,
  'green': #2fbfa0,
  'purple': #8782ff,
  'wizbii-purple': #8782ff,
  'yellow': #ffce67,
  'black': #000028,
  'grey-dark': #5f7484,
  'red': #df4545,
  'valid': #7ed321,
  'grey': #b3b7c7,
  'orange': #ff9959,
);
$status-participation-colors: (
  'applied': #8782ff,
  'waiting': #ffce67,
  'selected': #ff5c75,
  'invited': #399af2,
  'confirmed': #11b684,
  'rejected': #f64a91,
  'canceled': #f64a91,
  'refused': #f64a91,
  'attended': #11b684,
  'waiting_resume': #f64a91,
);
