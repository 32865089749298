@use 'variables/size';

.modal-bloc {
  margin-bottom: 2.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.875rem;

    &--title {
      font-size: size.$sm;
      color: var(--midnight-blue-100);
    }

    &--error {
      color: var(--red-100);
      font-size: size.$xxs;
    }

    &__tools {
      display: flex;

      &--btn-save {
        margin: 0;
      }

      &__btn-close {
        margin-left: 0.625rem;
      }
    }
  }

  &__title {
    font-size: 0.875rem;
    color: var(--midnight-blue-100);
  }

  &__row {
    display: flex;

    &--input {
      flex: 1;
      margin-right: 1.25rem;
      margin-bottom: 1.25rem;

      &:last-child {
        margin-right: initial;
      }
    }

    &--select {
      flex: 1;
      margin-right: 1.25rem;
      margin-bottom: 1.25rem;

      &:last-child {
        margin-right: initial;
      }
    }

    &--right {
      justify-content: flex-end;
    }

    &--center {
      justify-content: center;
    }

    &--align-center {
      align-items: center;
    }

    &--editor {
      height: 80vh;
    }
  }
}
