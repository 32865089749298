.wiz-dialog {
  max-width: 90vw;
  max-height: 100vh;

  .mdc-dialog {
    .mdc-dialog__surface {
      background-color: var(--purple-5);
      margin: 0;
      padding: 0;
      overflow-x: hidden;
      border-radius: 20px;
      height: fit-content;
    }
  }

  .mat-mdc-dialog-content {
    margin: 0 !important;
    padding: 0 !important;
  }
}
