@use 'variables/size';

.tooltip {
  max-width: 18.75rem !important;
  background-color: var(--purple-100) !important;
  padding: 0.75rem 1.67rem !important;
  font-size: size.$xxs;
  border-radius: 4px !important;
}

.tooltip-top::after {
  border-color: var(--purple-100) transparent transparent !important;
}

.tooltip-right::after {
  border-color: transparent var(--purple-100) transparent transparent !important;
}
