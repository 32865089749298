.upload-action-button {
  .file-picker {
    background-color: transparent !important;
    width: 100%;

    &__container {
      width: 100%;
      justify-content: space-evenly;
    }

    &__icon {
      color: var(--purple-100);
    }
  }
}

.file-picker-clean {
  .file-picker {
    background-color: transparent !important;
    padding: 0.9375rem;

    &__icon {
      display: none;
    }

    &__label {
      margin-left: initial;
      font-size: 1em;
    }
  }
}
