@use './override/ckeditor/ckeditor' as wzbCkeditor;
@use './override/material/index';

// stylelint-disable no-invalid-position-at-import-rule

@use 'theme';
@use 'variables/colors';
@use 'variables/grid';
@use 'headers/header-general';
@use 'headers/header-detail';
@use 'widgets/datepicker';
@use 'widgets/button';
@use 'widgets/brand-buttons';
@use 'widgets/badge';
@use 'widgets/button-file-picker';
@use 'widgets/ngselect';
@use 'widgets/modal';
@use 'widgets/tab-header';
@use 'widgets/ngx-charts';
@use 'widgets/ng2-tooltip-directive';
@use 'widgets/ngx-slider';

html {
  overflow: hidden scroll;
}

html,
body {
  font-family: Montserrat, sans-serif;
  background-color: var(--midnight-blue-5);
  min-height: 100vh;
  color: var(--midnight-blue-100);
}

* {
  box-sizing: border-box;
}

// stylelint-disable selector-no-qualifying-type
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: initial;
}

.lock {
  overflow: hidden;

  body {
    overflow: visible scroll;
  }
}

[hidden] {
  display: none !important;
}

.form__remuneration__checkbox .label-button-container {
  display: block !important;
}

.ul-clean {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.page-unauthorized-component {
  position: absolute;
  z-index: 10001;
}

@include wzbCkeditor.wzbCkeditor(var(--purple-100));

.ng-scrollbar {
  width: 100%;
}
