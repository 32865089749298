@use 'sass:color' as color;

.tab-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 2.5rem;

  &__group {
    display: flex;
    align-items: stretch;
    position: relative;
  }

  .group__item {
    position: relative;

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      top: 0.8125rem;
      left: 0;
      width: 0.0625rem;
      height: calc(100% - 1.625rem);
      background-color: var(--midnight-blue-10);
    }

    &--title {
      font-size: 1.125rem;
      font-weight: 500;
      padding: 1.25rem 0.9063rem 1.25rem 0;
      margin: 0;
    }

    &--link {
      font-size: 0.875rem;
      font-weight: 500;
      padding: 0 0.9375rem;

      &:hover {
        background-color: var(--purple-light-alpha);
      }

      &:disabled {
        color: var(--midnight-blue-10);
        background-color: initial;
        cursor: initial;
      }
    }

    &--popup {
      display: flex;
    }

    &--btn {
      display: flex;
      align-items: center;
    }
  }
}

.group__item--search {
  display: flex;
  align-items: center;

  .search-bar {
    margin: initial !important;

    &__input-large {
      width: 20rem;
      margin-right: 0.3125rem;
    }
  }
}

.popup-menu {
  z-index: 99;
  position: absolute;
  right: 0;
  bottom: -0.5rem; // transform relative to bottom
  display: flex;
  flex-direction: column;
  min-width: 12rem;
  background-color: white;
  border-radius: 10px;
  font-size: 0.9375rem;
  box-shadow: 0 0.25rem 1.75rem 0 var(--box-shadow-6);
  transform: translateY(100%);
  animation: jobs-popup-menu 0.15s ease-out backwards;
  overflow: hidden;

  &__link {
    display: flex;
    align-items: center;
    padding: 0.9375rem;
    outline-offset: -0.125rem;
    text-align: left;
    color: inherit;
    position: relative;
    font-size: 0.75rem;
    text-decoration: none;

    &--file {
      padding: 0;
    }

    &:hover {
      text-decoration: none;
      color: var(--purple-100);
      background-color: var(--purple-light-alpha);
      transition:
        color,
        background-color 0.2s;
    }

    &:disabled {
      color: var(--navy-blue-15);
      background-color: initial;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0.0625rem;
      width: calc(100% - 1.875rem);
      margin: 0 0.9375rem;
      background-color: var(--midnight-blue-10);
    }

    &__icon {
      margin-right: 0.625rem;
      color: var(--purple-100);
    }
  }
}
