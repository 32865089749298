@use 'variables/size';

.dp-popup {
  width: 21.25rem;
  padding: 1.25rem;
  box-sizing: border-box;

  .dp-calendar-wrapper {
    border: none;
  }

  .dp-calendar-nav-container {
    width: 100%;
    border: none;
    text-align: center;
    margin: 0 0 1.25rem;
  }

  .dp-nav-header {
    left: 10%;
    width: 80%;
    z-index: 10;

    .dp-nav-header-btn {
      outline: none;
      border: none;
      color: var(--purple-100);
      font-weight: bold;
      font-size: size.$xs;
      text-transform: uppercase;
    }
  }

  .dp-nav-btns-container {
    width: 100%;
    height: 1.25rem;
    top: 0.015rem;
    right: 0;
    transform: translateY(0);

    .dp-calendar-nav-container-left,
    .dp-calendar-nav-container-right {
      position: absolute;
      z-index: 20;

      button {
        border: none;
        outline: none;
      }
    }

    .dp-calendar-nav-container-left {
      left: 0.125rem;
    }

    .dp-calendar-nav-left::before {
      border-color: var(--purple-100);
    }

    .dp-calendar-nav-container-right {
      right: 0.125rem;
    }

    .dp-calendar-nav-right::before {
      border-color: var(--purple-100);
    }
  }

  .dp-day-calendar-container {
    width: 18.75rem;
  }

  .dp-weekdays {
    width: 100%;
    margin-bottom: 0.9375rem;

    .dp-calendar-weekday {
      width: calc(100% / 7);
      font-size: size.$xxs;
      text-transform: uppercase;
      border: none;
    }
  }

  .dp-calendar-week {
    width: 100%;
  }

  .dp-calendar-day {
    width: calc(100% / 7);
    height: 2.625rem;
    border: none;
    color: var(--midnight-blue-100);
    outline: none;
  }

  .dp-calendar-day.dp-current-day {
    color: var(--purple-100);
    font-weight: bold;
  }

  .dp-calendar-day.dp-prev-month,
  .dp-calendar-day.dp-next-month {
    color: var(--navy-blue-40);
  }

  .dp-calendar-day.dp-selected {
    background-color: var(--purple-100);
    color: white;
    border-radius: 10px;
    font-weight: bold;
  }

  .dp-month-calendar-container {
    width: 18.75rem;
  }

  .dp-calendar-month {
    width: calc(100% / 4);
    border: none;
    background-color: white;
    text-transform: uppercase;
    outline: none;

    &.dp-current-month {
      color: var(--purple-100);
      font-weight: bold;
    }
  }
}

.input-container-date {
  .dp-picker-input {
    color: var(--midnight-blue-100);
    border: none;
    font-size: size.$xs;
    text-align: center;
    width: 5.5rem;
    height: 1.375rem;
  }

  &.fake-input .dp-picker-input {
    background-color: transparent;
    border: none;
    width: 100%;
    margin: 1.6875rem auto 0.6875rem;
    text-align: left;
    color: var(--midnight-blue-100);
    padding: 0 1.25rem;
    outline: var(--purple-100);
    line-height: 1.15;
  }
}

.dp-popup[ng-reflect-ng-switch='time'] {
  width: 7.5rem;
  height: 6.25rem;
  padding: 0.625rem 1.25rem 0;

  .dp-time-select-control-down,
  .dp-time-select-control-up {
    border: none;
  }

  .dp-time-select-control-down::before,
  .dp-time-select-control-up::before {
    border-color: var(--purple-100);
  }
}
