@use 'sass:color' as color;
@use 'variables/colors' as colors;
@use 'variables/size';

.btn {
  border-radius: 20px;
  border: none;
  cursor: pointer;
  outline: none !important;
  background-color: none;
  font-size: size.$xs;
  padding: 0.625rem 1.25rem;
  margin: auto 0.3125rem;
  display: inline-block;
  text-decoration: none;

  &:disabled {
    cursor: initial;
    opacity: 0.8;
  }

  &--icon {
    display: inline-block;
    color: currentcolor;
    margin: auto 0;
    padding: 0;
    cursor: pointer;

    &-text {
      vertical-align: text-top;
    }

    &-left {
      margin-right: 0.625rem;
    }

    &-right {
      margin-left: 0.625rem;
    }

    &-loader {
      display: inline-block !important;
    }
  }

  &--flat {
    box-shadow: none !important;
    padding: 0.4375rem 1.25rem;
  }

  &--square {
    height: 3.125rem;
    border-radius: 10px;
    font-size: 0.875rem;
  }

  &--top-action {
    display: flex;
    align-items: center;
    font-size: size.$xxs;
    font-weight: 500;
    border-radius: 20px;
    box-shadow: 0 0.125rem 0.3125rem 0 var(--purple-15);
    background-color: white;
    color: var(--grey-dark);
    padding: 0.75rem 1.5625rem;

    &__picto {
      display: inline-block;
      color: var(--purple-100);
      margin-right: 0.625rem;
    }
  }

  &--flex {
    display: flex !important;
    align-items: center !important;
  }

  &--ghost.btn {
    color: inherit;
    background-color: transparent;
    transition:
      background-color 150ms ease-out,
      color 150ms ease-out;
    box-shadow: none;

    &:hover {
      color: white;
      background-color: var(--purple-100);
    }
    // stylelint-disable selector-max-specificity
    &:hover .btn--icon-ghost {
      color: white;
    }
  }
}

@each $name, $color in colors.$colors-list {
  .btn--icon-#{$name} {
    color: $color;
    font-weight: 500;
    font-size: size.$xxs;
    padding: initial;
  }

  .btn--#{$name} {
    color: white;
    background-color: $color;
    font-size: size.$xxs;
    box-shadow: 0 0.625rem 0.625rem 0 color.scale($color, $alpha: -80%);
  }

  .btn--white-#{$name} {
    color: $color;
    background-color: white;
    font-size: size.$xxs;
    box-shadow: 0 0.625rem 0.625rem 0 color.scale($color, $alpha: -80%);
  }
}

.btn--event-edition {
  font-size: 0.875rem;
  font-weight: 500;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn-clean {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.btn--event-edition-delete {
  background-color: transparent;
  box-shadow: none;
  border-radius: initial;
}

.btn--event-edition-save {
  border-radius: 6px;
  background-color: white;
  color: var(--purple-100);
  padding: 1rem 1.25rem;
}
