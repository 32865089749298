@use 'sass:color' as color;
@use 'variables/size' as size;

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
@keyframes to-up {
  from {
    top: calc(3.75rem / 2 - 0.687rem);
  }

  to {
    top: 0.75rem;
  }
}

.ng-select-container {
  border-radius: 6px;
  border: solid 0.0625rem var(--midnight-blue-10);
  height: 3.75rem;
  background-color: white;

  .ng-placeholder {
    font-size: size.$xs;
    color: var(--navy-blue-40);
    transition: all ease 0.2s;
    font-weight: 500;
  }

  .ng-value-container {
    align-items: center;
    margin-left: 1.25rem;

    .ng-input {
      left: initial !important;
    }
  }

  .ng-spinner-loader {
    top: calc(50% - 0.593rem);
  }

  .ng-clear-wrapper {
    display: none;
  }

  .ng-arrow-wrapper {
    height: 100%;
    padding: initial;
    width: 3.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    .ng-arrow {
      position: relative !important;
      display: inline-block !important ;
      background-color: transparent !important;
      height: 1.5rem !important;
      width: 1.5rem !important;
      transition: transform 100ms ease-in-out;

      &::before,
      &::after {
        content: '';
        top: 0.5rem;
        height: 0.625rem;
        width: 0.125rem;
        position: absolute;
        background-color: var(--midnight-blue-100);
        border-radius: 2px;
        display: block;
      }

      &::before {
        left: 58%;
        transform: rotate(40deg);
      }
      &::after {
        right: 58%;
        transform: rotate(-40deg);
      }
    }
  }
}

.ng-select-opened {
  .ng-arrow-wrapper {
    .ng-arrow {
      transform: rotate(-180deg);
    }
  }
}

.ng-select-focused,
.ng-select-filtered,
.ng-has-value {
  .ng-value-container {
    align-items: flex-end;

    .ng-value {
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
    }

    .ng-input {
      bottom: 0.75rem;
      width: auto !important;
    }
  }

  .ng-placeholder {
    display: block !important;
    position: absolute;
    top: 0.75rem;
    color: var(--purple-100);
    font-size: size.$xxs;
    font-weight: 500;
    animation: to-up 0.2s;
  }
}

.ng-dropdown-panel {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0.625rem 1.25rem 0 var(--purple-light-alpha);
  border: solid 0.062rem var(--midnight-blue-10);

  &.ng-select-bottom {
    margin-top: 0.625rem;
  }

  &.ng-select-top {
    margin-bottom: 0.625rem;
    bottom: 100%;
    box-shadow: 0 -0.625rem 1.25rem 0 var(--purple-light-alpha);
  }

  .ng-dropdown-panel-items {
    padding: 1.562rem 1.875rem;
  }

  .ng-option {
    display: flex !important;
    align-items: center;
    font-size: size.$xs;
    font-weight: 500;
    line-height: 2;
    color: var(--navy-blue-40);
    margin-left: 1rem;
  }

  .ng-option.ng-option-marked,
  .ng-option.ng-option-selected {
    color: var(--purple-100);
  }

  .ng-optgroup {
    padding: 1.016rem 0.3125rem;

    .ng-option-label {
      color: var(--purple-100);
      padding: 0.125rem 0.375rem;
      border-radius: 3px;
      font-weight: 500;
      box-shadow: 0 0.125rem 0.312rem var(--default-box-shadow);
    }
  }
}

.ng-select--invalid {
  .ng-select-container {
    border: 0.0625rem solid var(--red-100);

    .ng-placeholder {
      color: var(--red-100);
    }
  }
}

.ng-select--disable,
.ng-select-disabled {
  .ng-select-container {
    background-color: var(--navy-blue-15);
  }
}

.ng-select-multiple {
  .ng-value-container {
    .ng-value-label,
    .ng-value-icon {
      background-color: var(--purple-100);
      color: white;
      padding: 0.1rem 0.3rem;
      font-weight: 500;
    }

    .ng-value-label {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }

    .ng-value-icon {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      padding: 0.1rem 0 0.1rem 0.3rem;
    }

    .ng-input {
      margin: 1.75rem auto 0.875rem;
    }
  }

  .ng-select-container {
    min-height: 3.875rem;
    height: initial;
  }

  .ng-has-value {
    align-items: center;

    .ng-placeholder {
      display: none !important;
    }

    .ng-value {
      margin: 0.3125rem 0.125rem;
    }

    .ng-input {
      margin: auto;
    }
  }

  .ng-arrow-wrapper {
    height: initial;
    min-height: 3.75rem;
  }
}

.ng-select--no-arrow {
  .ng-select-container {
    .ng-arrow-wrapper {
      display: none;
    }
  }
}

ng-select.ng-select--invisible {
  .ng-select-container {
    border-radius: 0;
    border: none;
    background-color: transparent;
    color: currentcolor;

    .ng-value-container {
      .ng-input > input {
        color: currentcolor;
      }
    }

    .ng-arrow-wrapper {
      width: initial;
      background-color: transparent;
      border-left: none;

      .ng-arrow {
        background-color: currentcolor;
        border: none;

        &::before,
        &::after {
          background-color: currentcolor;
        }
      }
    }
  }

  .ng-dropdown-panel {
    .ng-option {
      margin-left: 0;
    }

    .ng-dropdown-panel-items {
      padding: 1rem;
    }
  }
}

ng-select.ng-select--current-color {
  .ng-select-container {
    border-radius: 0;
    border: none;
    background-color: transparent;
    color: currentcolor;

    .ng-arrow-wrapper {
      width: initial;
      background-color: transparent;
      border-left: none;

      .ng-arrow {
        background-color: currentcolor;
        border: none;
      }
    }

    .ng-placeholder {
      color: currentcolor;
    }
  }

  .ng-select-focused,
  .ng-select-filtered,
  .ng-has-value {
    .ng-placeholder {
      display: block !important;
      position: absolute;
      top: 0.75rem;
      color: currentcolor;
      font-size: size.$xxs;
      font-weight: 500;
      animation: to-up 0.2s;
    }
  }
}

.ng-select--cross .ng-select-container.ng-has-value {
  .ng-clear-wrapper {
    display: inline-block;
    margin-top: 1rem;
    margin-right: 0.25rem;

    .ng-clear {
      font-size: 2rem !important;
      font-weight: lighter;
    }
  }

  .ng-arrow-wrapper {
    display: none;
  }
}

ng-select.ng-select--clearable {
  .ng-clear-wrapper {
    width: initial;
    background-color: transparent;
    border-left: none;
    height: 100%;
    padding: 0;
    padding: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    .ng-clear {
      line-height: 1;
      height: 1.5rem !important;
      width: 1.5rem !important;
      font-size: 1.5625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.1875rem;
    }
  }
}

.ng-select--emphasis-tag {
  .ng-input {
    width: 100% !important;
  }

  .ng-select-container .ng-value-container .ng-input > input {
    text-transform: uppercase;
  }

  .ng-option :not(.ng-tag-label) {
    padding: 0.2rem 1rem;
  }

  .ng-dropdown-panel-items {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
